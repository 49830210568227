import React from "react"
import classNames from "classnames"
import { Text } from "../Core"
import { ButtonGroupMap } from "../Button"
import { Section, Columns } from "../Containers"

const CallToAction = ({
  heading,
  blurb,
  buttons,
  colorBack,
  className,
  sideColumnsSize = 5
}) => {
  const mainClasses = classNames("call-to-action", className)
  return (
    <Section
      className={mainClasses}
      colorBack={colorBack}
      colorBackSecondary={colorBack}>
      <Columns sideColumnsSize={sideColumnsSize}>
        <div className="column has-text-centered">
          <Text as="h3" className="mt-0" text={heading} />
          <Text as="p" text={blurb} />
          {buttons && (
            <ButtonGroupMap
              buttonGroupClass="mt-3"
              isCentered
              buttons={buttons}
            />
          )}
        </div>
      </Columns>
    </Section>
  )
}

export default CallToAction
